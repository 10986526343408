import axios from '@/plugins/axios';

export async function get_reference_price() {
  let price = 6.8;
  let response;
  try {
    response = await axios({
      method: 'GET',
      url: 'plan/reference_price/',
    })
  } catch (errors) {
    // fail to fetching reference price from binance
  }
  if (response.status === 200) {
    const obj = response.data;
    price = obj.reference_price
  }
  return price;
}

const Price = {
  DOMAIN_PRICE: 30, // domain price per 1 domain
  RATE_CNY_USDT: 6.8,
  TRAFFIC_PRICE: 0.5, // traffic price per 1G 
}

export default Price;

