<template>
  <div>
      <CModal :title="$t('checkout.SelectPaymentMethod')"
            :closeOnBackdrop="false"
            :show.sync="paymentShow">
        <CAlert color="info" class="alert-checkout"><span id="price">${{ amountPriceUSD }} USDT</span></CAlert>
        <label for="cryptocurrency">{{ $t('checkout.Cryptocurrency') }}</label>
        <ul class="coins">
            <li v-for="coin in coinSupports"
                :key="'coin-' + coin.id"
                v-if="coin.is_show"
                v-bind:class="activeStyle(coin.code)"
                @click="selectPayment('cryptocurrency', coin.code)">
            <div class="box"></div>
            <img :src="coin.icon"/>
            <p>{{ coin.name }}</p>
            </li>
        </ul>

        <label for="balance">{{ $t('checkout.AccountBalance') }}: {{ $store.state.balance }} USDT</label>
        <ul class="coins">
            <li
                id="account_balance"
                key="coin-balance"
                @click="selectPayment('account_balance', 'balance')"
                v-bind:class="activeStyle('balance')"
            >
            <div id="account_balance_div" class="box"></div>
            <img :src="`${publicPath}coins/balance.png`" />
            <p id="account_balance_p">
                {{ $t('checkout.AccountBalance') }}
            </p>
            </li>
        </ul>

        <template v-slot:footer>
            <button
                type="button"
                class="btn btn-secondary"
                @click="$emit('hide');">
            {{ $t('Cancel') }}
            </button>
            <button
            id="pay"
            type="button"
            class="btn btn-primary"
            @click="clickPay()">
            {{ $t('Pay') }}
            </button>
        </template>
    </CModal>
    <payment-crypto :show.sync="showPaymentCrypto"
                    :amount="payment.amount"
                    :address="payment.address"
                    :coin="payment.currency2"/>
  </div>
</template>

<style scoped>
.alert-checkout {
  margin-bottom: 5px;
}
.reference-price {
  display: block;
  margin-bottom: 10px;
  font-weight: bold;
}
</style>

<script>
import axios from "@/plugins/axios.js";
import { payPlan, payDomain, renewPlan, fetchBalance } from '@/utilities/api'
import Price from '@/utilities/price'
import PaymentCrypto from './Crypto.vue'
import { bus } from '@/main'

export default {
  name: "PaymentCheckout",
  components: {
    PaymentCrypto,
  },
  data() {
    return {
      selectedCoin: "USDT",
      publicPath: process.env.BASE_URL,
      showPaymentCrypto: false,
      payment: {
        currency2: null,
        address: null,
        amount: 0
      }
    }
  },
  computed: {
    amountPriceUSD: function () {
      if (this.order.type === 'renew' || this.order.type === 'paytrial') {
        return this.order.amountPrice
      }
      return this.order.amountPrice
    },
    coinSupports: function() {
      return this.$store.state.coinSupports;
    }
  },
  props: {
    order: Object,
    paymentShow: Boolean,
  },
  watch: {
    paymentShow() {
      this.$emit('update:paymentShow', this.paymentShow)
    },
    showPaymentCrypto() {
      if (!this.showPaymentCrypto) {
        this.payment = {
          currency2: null,
          address: null,
          amount: 0
        }
      }
    }
  },
  methods: {
    activeStyle(coin_code) {
        return {
            active: this.selectedCoin==coin_code
        }
    },
    selectPayment(payment, coin="") {
      this.selectedPaymentType = payment
      this.selectedCoin = coin
    },

    clickPay() {
      if (this.selectedPaymentType) {
        if (this.selectedPaymentType === 'cryptocurrency') {
          if (!this.selectedCoin) {
            this.flash(this.$t('checkout.WarningCoin'), 'warning')
            return
          }
        }
        if (this.selectedPaymentType === 'account_balance') {
          if (parseFloat(this.$store.state.balance) < parseFloat(this.$store.state.transaction.checkout.amount_currency1)) {
            this.flash(this.$t('checkout.WarningBalance'), 'warning')
            return
          }
          this.selectedCoin = 'USDT'
        }

        if( this.order.type == "plan" ) {
            payPlan(this.order.plan.id, this.selectedCoin, this.selectedPaymentType, this.order.additional_domain_number, this.order.additional_traffic).then(response => {
              this.flash(this.$t('checkout.SuccessPaymentType'), 'success', {timeout: 4000})
              this.showPaymentChoose = false
              if (this.selectedPaymentType === 'cryptocurrency') {
                this.payment = response.data
                this.showingPaymentCrypto()
                this.$emit('update:paymentShow', false)
                bus.$emit('updateSelectedSubscription');
                bus.$emit('updateQuota');
              }
              if (this.selectedPaymentType === 'account_balance') {
                  axios.get('plan/balance/me/').then(response => {
                    this.$store.state.balance = response.data.amount
                    this.$emit('update:paymentShow', false)
                    bus.$emit('updateSelectedSubscription');
                    bus.$emit('updateQuota');                    
                  }).catch(error => console.log(error))
              }
            }).catch(({errors}) => {
              errors.forEach(item => {
                this.flash(item, 'warning')
              })
            })
        }
        else if( this.order.type == "domain" ) {
            payDomain(this.selectedCoin, this.selectedPaymentType, this.order.additional_domain_number, this.order.additional_traffic).then(response => {
              this.flash(this.$t('checkout.SuccessDomainPaymentType'), 'success', {timeout: 4000})
              this.showPaymentChoose = false
              if (this.selectedPaymentType === 'cryptocurrency') {
                this.payment = response.data
                this.showingPaymentCrypto()
                this.$emit('update:paymentShow', false)
                bus.$emit('updateSelectedSubscription');
                bus.$emit('updateQuota');                
              }
              if (this.selectedPaymentType === 'account_balance') {
                  axios.get('plan/balance/me/').then(response => {
                    this.$store.state.balance = response.data.amount
                    this.$emit('update:paymentShow', false)
                    bus.$emit('updateSelectedSubscription');
                    bus.$emit('updateQuota');                    
                  }).catch(error => console.log(error))
              }
            }).catch(({errors}) => {
              errors.forEach(item => {
                this.flash(item, 'warning')
              })
            })
        }        
        else if ( this.order.type == "renew" || this.order.type == "paytrial"  ) {
            renewPlan(
                this.order.subscription_id,
                this.selectedCoin,
                this.selectedPaymentType,
            ).then(response => {
                this.selectedCoin = null
                this.flash(this.$t('checkout.SuccessPaymentType'), 'success', {timeout: 4000})
                if (this.selectedPaymentType === 'cryptocurrency') {
                  this.payment = response.data
                  this.showingPaymentCrypto()
                  this.$emit('update:paymentShow', false)
                  bus.$emit('updateSelectedSubscription');
                  bus.$emit('updateQuota');                   
                }
                else if (this.selectedPaymentType === 'account_balance') {
                    axios.get('plan/balance/me/').then(response => {
                      this.$store.state.balance = response.data.amount
                      this.$emit('update:paymentShow', false)
                      bus.$emit('updateSelectedSubscription');
                      bus.$emit('updateQuota');
                    }).catch(error => console.log(error))
                    this.$emit('hide');
                }
            }).catch(({errors}) => {
                errors.forEach(item => {
                    this.flash(item, 'warning')
                })
            })
        }
      } else {
        this.flash(this.$t('checkout.WarningPaymentType'), 'warning')
      }
    },
    showingPaymentCrypto() {
      if (this.payment.address) {
        this.showPaymentCrypto = true
      }
    }
  }
};
</script>
<style scoped>
ul.coins {
  padding: 0;
  list-style: none;
}

ul.coins li {
  position: relative;
  display: inline-block;
  padding: 10px;
  margin: 10px;
  border: 1px solid #eee;
  border-radius: 10px;
  cursor: pointer;
}

ul.coins li.active {
  border: 1px solid #0064bd;
}

ul.coins li div.box {
  position: absolute;
  top: 0;
  width: 100px;
  height: 100px;
}

ul.coins li img {
  width: 100px;
  height: 100px;
}

ul.coins p {
  margin: 0;
  text-align: center;
}
</style>
